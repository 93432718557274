import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "src/components/MyButton/Button";
import {PhoneOutlined} from "@ant-design/icons";
import {Avatar} from "antd";

// Component
import IconBase from "src/components/IconBase";
import BlinkingDot from "src/components/BlinkingDot";
import LoadingText from "../component/LoadingText";
import Notice from "src/components/Notice";

// Utils
import useCall from "src/lib/useCall";
import {formatTime} from "../common/utils";
import {CALL_STATE, CALL_TYPE} from "src/constants/constants";
import STORAGE from "src/lib/storage";

// Reducer
import {setInit, setUnavailable, setUnknown} from "src/redux/crmCallingReducer";

function Calling(props) {
    const { phoneNumber } = props
    const { callState } = useSelector((state) => state.calling);
    const tokenCall = localStorage.getItem(STORAGE.TOKEN_CALL);

    const { dialCall, timeElapsed, enableMicro, onHandleMicro, terminateCall } = useCall(phoneNumber);

    const dispatch = useDispatch()

    useEffect(() => {
        if (callState === CALL_STATE.INIT) dialCall()
        if (callState === CALL_STATE.ENDED) dispatch(setUnavailable())
    }, [callState, dialCall, dispatch]);

    const handleOnCall = async () => {
        if ([CALL_STATE.RINGING, CALL_STATE.CONNECTED].includes(callState)) {
            terminateCall();
            return;
        }

        if (!tokenCall) {
            Notice({
                isSuccess: false,
                msg: "Không tồn tại token call. Vui lòng liên hệ với quản trị viên.",
            });
            return;
        }

        if (typeof window.ZenifyApp === 'undefined') {
            Notice({
                isSuccess: false,
                msg: "Zenify không được tải. Vui lòng liên hệ với quản trị viên.",
            });
            return;
        }

        try {
            await navigator.mediaDevices.getUserMedia({ audio: true })
        } catch (error) {
            Notice({
                isSuccess: false,
                msg: "Vui lòng kiểm tra microphone và thiết bị microphone",
            })
            return
        }
        dispatch(setInit({ phoneNumber, data: { callType: CALL_TYPE.CALL_OUTBOUND }, callType: CALL_TYPE.CALL_OUTBOUND }))
    }

    const renderCalling = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                <BlinkingDot size={10} style={{ marginBottom: '2px' }} />
                <div style={{ margin: '0 5px' }}>
                    {formatTime(timeElapsed)}
                </div>
                <Button
                    style={{
                        padding: '0 10px',
                        backgroundColor: "unset",
                        outline: 'none',
                        boxShadow: 'none',
                    }}
                    onClick={onHandleMicro}
                    type="primary"
                    shape="circle"
                    icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"} color={'#0a8bc2'}/>}
                    size={'large'}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div>
                {callState === CALL_STATE.INIT && <LoadingText text="Đang kết nối"/>}
                {callState === CALL_STATE.RINGING && <LoadingText text="Đang đổ chuông"/>}
                {callState === CALL_STATE.CONNECTED && renderCalling()}
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
            {renderContent()}
            <Avatar
                onClick={handleOnCall}
                icon={<PhoneOutlined
                    disabled={callState === CALL_STATE.INIT}
                    style={{
                        fontSize: "20px",
                        color: [CALL_STATE.UNAVAILABLE, CALL_STATE.INIT].includes(callState) ? '#0a8bc2' : 'red' }} />}
                style={{ backgroundColor: "transparent", color: "#1890ff", fontSize: 18 }}
            />
        </div>
    );
}

export default Calling;