import React, { useEffect, useRef } from 'react';
import { notification, Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";

// Components
import Notice from "src/components/Notice";

// Utils
import useCall from 'src/lib/useCall';
import CRMService from "src/services/CRMService";
import {CALL_STATE, CALL_TYPE} from "src/constants/constants";

// Reducer
import { setUnavailable } from 'src/redux/crmCallingReducer';

// Style
import './styles.css';

function Index() {
    const dispatch = useDispatch();
    const audioRef = useRef(null);
    const { callState, data, callType } = useSelector((state) => state.calling);
    const { answerCall, terminateCall } = useCall();
    const navigate = useNavigate()

    useEffect(() => {
        if ([CALL_STATE.ENDED, CALL_STATE.NO_RESPONSE].includes(callState) && callType === CALL_TYPE.CALL_INBOUND) {
            CRMService.callLog({ phone: data.caller, time: 0, code_call: data.callId })
                .then(() => console.log('Call log success'))
                .catch((err) => console.error('Call log error:', err));
        }
    }, [callState]);

    useEffect(() => {
        // Phát âm thanh khi có cuộc gọi đến
        const originalTitle = document.title;
        // Thay đổi tiêu đề khi có cuộc gọi đến
        document.title = `Cuộc gọi đến ${data.caller}`;
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error("Không thể phát âm thanh:", error);
            });
        }

        const handleAnswer = async () => {
            try {
                const res = await CRMService.getIdCallerInfo({ phone: data.caller });
                const { success } = res;
                if (success.order_id) {
                    navigate('/bussiness/order', { state: { isCalling: true, id: success.order_id } });
                } else {
                    navigate('/bussiness/contact', { state: { isCalling: true, id: success.contact_id } });
                }
                answerCall();
            } catch (err) {
                console.error("search caller failed", err);
                Notice({
                    isSuccess: false,
                    msg: "Không tìm thấy thông tin người gọi!",
                });
                answerCall();
            } finally {
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }
                notification.destroy();
                // document.title = originalTitle;
            }
        };

        const handleReject = () => {
            terminateCall()
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            dispatch(setUnavailable());
            terminateCall();
            notification.destroy();
            // document.title = originalTitle
        };

        // Mở thông báo cuộc gọi đến
        notification.open({
            message: 'Cuộc gọi đến',
            placement: 'topLeft',
            description: `Bạn có cuộc gọi đến từ ${data.caller}`,
            duration: 0,
            className: 'incoming-call-notification',
            closeIcon: false,
            icon: <PhoneOutlined className="incoming-call-icon" style={{ color: '#0d99ff', fontSize: '24px' }} />,
            btn: (
                <>
                    <Button style={{ marginRight: "10px" }} type="primary" onClick={handleAnswer}>
                        Nhấc máy
                    </Button>
                    <Button danger onClick={handleReject}>
                        Từ chối
                    </Button>
                </>
            ),
        });

        // Cleanup: dừng âm thanh khi component unmount
        return () => {
            notification.destroy();
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [dispatch, terminateCall]);

    return (
        <>
            <audio ref={audioRef} src="/sounds/incommingCall.mp3" loop />
            <div></div>
        </>
    );
}

export default Index;
