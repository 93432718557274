import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { maskPhoneNumber } from "src/lib/utils";

const Index = (props) => {
    const {
        canShowPhone,
        phoneNumber: initialPhone,
        disabled,
        form,
        name
    } = props;

    const [phoneNumber, setPhoneNumber] = useState(initialPhone);
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        setPhoneNumber(initialPhone);
    }, [initialPhone]);

    const suffixIcon = canShowPhone && (
        isHidden ? (
            <EyeInvisibleOutlined onClick={() => setIsHidden(false)} style={{ cursor: 'pointer' }} />
        ) : (
            <EyeOutlined onClick={() => setIsHidden(true)} style={{ cursor: 'pointer' }} />
        )
    );

    return (
        <Input
            disabled={disabled}
            value={
                canShowPhone
                    ? (isHidden ? maskPhoneNumber(phoneNumber) : phoneNumber)
                    : maskPhoneNumber(phoneNumber)
            }
            suffix={canShowPhone ? suffixIcon : null}
        />
    );
};

Index.propsType = {
    canShowPhone: PropTypes.bool,
    phoneNumber: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string
}

Index.defaultProps = {
    canShowPhone: false,
    phoneNumber: '',
    disabled: false,
    name: "phone"
}

export default Index;