import styled from "styled-components"
import { Button } from "antd"

export const StyleTabsCustom = styled.div`
  button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
    display: none;
  }
  .Text-title {
    color: #154398;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
  }
  .title-type-1 {
    margin-bottom: 16px;
    padding-top: 0px;
    padding-bottom: 16px;
  }
  .title-type-1:after {
    background-image: linear-gradient(
      45deg,
      rgb(255, 255, 255) 0%,
      rgb(8, 174, 234) 100%
    );
    bottom: -2px;
    content: "";
    height: 4px;
    left: 40px;
    margin: 0 0 0 -40px;
    position: absolute;
    width: 20%;
  }
  .text-tile-tabs {
    font-size: 18px;
    line-height: 25.4px;
    font-weight: 600;
    color: rgb(2, 85, 127);
  }
  .ant-tabs-content {
    position: unset;
  }
  .ant-tabs-tab-active .text-tile-tabs {
    color: rgb(8, 174, 234);
  }
  .ant-tabs-tab.ant-tabs-tab-active:after {
    bottom: -2px;
    content: "";
    height: 4px;
    left: 40px;
    margin: 0 0 0 -40px;
    position: absolute;
    width: 100%;
  }
  .text-input {
    color: var(--text1, #212529);
    font-size: 14px;
    font-weight: 600;
  }
    @media (max-width: 600px) {
        .text-tile-tabs {
            font-size: 16px;
            line-height: 22px;
        }

        .ant-tabs-nav {
            margin-bottom: 5px;
        }
    }
`

