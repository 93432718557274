import { useCallback, useEffect, useState, useRef } from 'react';
import {useSelector} from "react-redux";
import {CALL_STATE} from "src/constants/constants";
import {ZenifyApp} from "../initZenify";

function useCall(phoneNumber) {
    const { callState } = useSelector((state) => state.calling);

    const [timeElapsed, setTimeElapsed] = useState(0);
    const [enableMicro, setEnableMicro] = useState(true);
    const timerRef = useRef(null);

    // Outgoing call: đánh dấu là outbound (isInbound = false)
    const dialCall = useCallback(() => {
        if (ZenifyApp.activateVoice) {
            ZenifyApp.activateVoice();
        }
        if (ZenifyApp.dialCall) {
            ZenifyApp.dialCall(phoneNumber);
        }
    }, [phoneNumber]);

    // Terminate call
    const terminateCall = useCallback(() => {
        if (ZenifyApp.terminate) {
            ZenifyApp.terminate();
        }
    }, []);

    // Answer inbound call
    const answerCall = useCallback(() => {
        if (ZenifyApp.answerCall) {
            ZenifyApp.answerCall();
        } else if (window.ZenifyPhone && window.ZenifyPhone.answer_call) {
            window.ZenifyPhone.answer_call();
        }
    }, []);

    // Hold and unhold
    const holdCall = useCallback(() => {
        if (ZenifyApp.hold) {
            ZenifyApp.hold();
        }
    }, []);

    const unHoldCall = useCallback(() => {
        if (ZenifyApp.unhold) {
            ZenifyApp.unhold();
        }
    }, []);

    // Mute/unmute micro
    const mute = useCallback(() => {
        setEnableMicro(false);
        if (ZenifyApp.mute) {
            ZenifyApp.mute();
        }
    }, []);

    const unMute = useCallback(() => {
        setEnableMicro(true);
        if (ZenifyApp.unmute) {
            ZenifyApp.unmute();
        }
    }, []);

    const onHandleMicro = useCallback(() => {
        enableMicro ? mute() : unMute();
    }, [enableMicro, mute, unMute]);

    // Effect đếm thời gian khi cuộc gọi đã kết nối
    useEffect(() => {
        if (callState === CALL_STATE.CONNECTED) {
            setTimeElapsed(0);
            timerRef.current = setInterval(() => {
                setTimeElapsed(prev => prev + 1);
            }, 1000);
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [callState]);

    // Nếu chưa có cuộc gọi hoặc đã kết thúc thì gọi, nếu đang trong cuộc gọi thì kết thúc
    // const handleButtonClick = useCallback(() => {
    //     if (callState === CALL_STATE.UNAVAILABLE || callState === CALL_STATE.ENDED) {
    //         dialCall();
    //     } else {
    //         terminateCall();
    //     }
    // }, [callState, dialCall, terminateCall]);

    return {
        dialCall,
        terminateCall,
        timeElapsed,
        enableMicro,
        onHandleMicro,
        // handleButtonClick,
        answerCall,
        holdCall,
        unHoldCall,
    };
}

export default useCall;
