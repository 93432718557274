import QueryString from "qs"
import http from "../index"
import {
  apiAssigned,
  apiAssignPermissions,
  apiCallLog,
  apiExportOrder,
  apiGetAllPermission,
  apiGetDistrict,
  apiGetGrowthStatistics,
  apiGetProvince,
  apiGetRolePermissions,
  apiGetStatistics,
  apiGetTopSales,
  apiGetWard,
  apiListPersonnel,
  apiOrderUpdateStatus,
  apiSalary,
  apiTotalPrice,
  apiUpdateStatusOrderVTP,
  apiCreateSalaryConfig,
  apiGetSalaryConfig,
  apiGetListSalaryConfig,
  apiGetDetailSalaryConfig,
  apiUpdateSalaryConfig,
  apiUpdateStatusOrderCOD,
  apiGetDashBoardHome,
  apiGetIdCallerInfo,
  apiRequestRefund,
  apiGetOrdersProcessed,
  apiSearchOrderVTP,
  apiGetCars,
  apiGetTotalPriceOrder,
  apiGetListWeeklyBonus,
  apiGetListCall,
  apiSearchInfoPhone,
  apiRestoreContact
} from "./urls"

// const getAllowanceHistory = body =>
//   http.get(apiAllowanceHistory + `?${QueryString.stringify(body)}`)
// const salaryHistoryCreate = body => http.post(apiSalaryHistoryCreate, body)
// const salaryHistoryUpdate = (id, body) =>
//   http.put(apiSalaryHistoryUpdate(id), body)
// const salaryHistoryDelete = params =>
//   http.delete(apiSalaryHistoryDelete, { params })
// const getContractList = params => http.get(apiGetListContract, { params })
const postAssigned = params => http.post(apiAssigned, params)
const getListPersonnel = params => http.get(apiListPersonnel, {params})
const exportOrder = params => http.get(apiExportOrder, {params})
const totalPrice = params => http.post(apiTotalPrice, params)
const orderUpdateStatus = params => http.put(apiOrderUpdateStatus, params)
const callLog = body => http.post(apiCallLog, body)
const getProvince = () => http.get(apiGetProvince)
const getDistrict = params => http.get(apiGetDistrict, {params})
const getWard = params => http.get(apiGetWard, {params})
const getStatistics = params => http.get(apiGetStatistics, {params})
const getGrowthStatistics  = params => http.get(apiGetGrowthStatistics, {params})
const getRolePermissions = (id, params) => http.get(apiGetRolePermissions(id), {params})
const getAllPermission = () => http.get(apiGetAllPermission)
const updateStatusOrderVTP = (body) => http.put(apiUpdateStatusOrderVTP, body)
const updateStatusOrderCOD = (id, body) => http.put(apiUpdateStatusOrderCOD(id), body)
const requestRefund = (id) => http.post(apiRequestRefund(id))
const updatePermissions = (id, body) => http.post(apiAssignPermissions(id), body)
const getSalary = (params) => http.get(apiSalary, {params})
const getTopSales = () => http.get(apiGetTopSales)
const createSalaryConfig = body => http.post(apiCreateSalaryConfig, body)
const updateSalaryConfig = (id, body) => http.put(apiUpdateSalaryConfig(id), body)
const getSalaryConfig = () => http.get(apiGetSalaryConfig)
const getListSalaryConfig = (params) => http.get(apiGetListSalaryConfig, {params})
const getDetailSalaryConfig = (id) => http.get(apiGetDetailSalaryConfig(id))
const getDashBoardHome = (id) => http.get(apiGetDashBoardHome)
const getIdCallerInfo = (params) => http.get(apiGetIdCallerInfo, {params})
const getOrdersProcessed = () => http.get(apiGetOrdersProcessed)
const searchOrderVTP = (params) => http.get(apiSearchOrderVTP, {params})
const getCars = () => http.get(apiGetCars)
const getTotalPriceOrder = (params) => http.get(apiGetTotalPriceOrder, { params })
const getListWeeklyBonus = (params) => http.get(apiGetListWeeklyBonus, { params })
const getListCall = (params) => http.get(apiGetListCall, { params })
const searchInfoPhone = (params) => http.get(apiSearchInfoPhone, { params })
const restoreContact = (params) => http.post(apiRestoreContact, params)

const CRMService = {
  postAssigned,
  getListPersonnel,
  exportOrder,
  totalPrice,
  orderUpdateStatus,
  callLog,
  getProvince,
  getDistrict,
  getWard,
  getStatistics,
  getGrowthStatistics,
  getRolePermissions,
  getAllPermission,
  updateStatusOrderVTP,
  updateStatusOrderCOD,
  requestRefund,
  updatePermissions,
  getSalary,
  getTopSales,
  createSalaryConfig,
  updateSalaryConfig,
  getSalaryConfig,
  getListSalaryConfig,
  getDetailSalaryConfig,
  getDashBoardHome,
  getIdCallerInfo,
  getOrdersProcessed,
  searchOrderVTP,
  getCars,
  getTotalPriceOrder,
  getListWeeklyBonus,
  getListCall,
  searchInfoPhone,
  restoreContact
}

export default CRMService

