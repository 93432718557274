import React from 'react';
import { useSelector } from 'react-redux';

// Constants
import { CALL_STATE } from 'src/constants/constants';

// Components
import Calling from './Calling';
import IncomingNotify from './component/IncomingNotify';

// Utils
import { isMobileConst } from 'src/lib/utils';

const DecorateRNDCalling = ({ children }) => (
    <div
        id="calling"
        style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
            pointerEvents: 'none'
        }}
    >
        {children}
    </div>
);

const Index = () => {
    const { callState } = useSelector((state) => state.calling);

    let content = null;
    if (callState === CALL_STATE.NEW_NOTIFY) {
        content = <IncomingNotify />;
    } else if (
        [CALL_STATE.INIT, CALL_STATE.RINGING, CALL_STATE.CONNECTED, CALL_STATE.ENDED, CALL_STATE.NO_RESPONSE].includes(callState) &&
        !isMobileConst
    ) {
        content = <Calling />;
    }

    return content ? <DecorateRNDCalling>{content}</DecorateRNDCalling> : null;
};

export default Index;
