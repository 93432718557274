import React, { useState } from 'react';
import { DatePicker } from 'antd';
import './custom.css';

const CustomRangePicker = (props) => {


    return (
        <DatePicker.RangePicker {...props} size="small" />
    );
};

export default CustomRangePicker;
