import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import { Rnd } from 'react-rnd';
import {Button, Tooltip} from 'antd';
import { ArrowsAltOutlined, CloseOutlined, ShrinkOutlined, LoadingOutlined } from "@ant-design/icons";

// Services
import CRMService from "src/services/CRMService";

// Components
import IconBase from "src/components/IconBase";
import LoadingText from "./component/LoadingText";

// Constants
import {CALL_STATE, CALL_TYPE} from "src/constants/constants";

// Utils
import STORAGE from "src/lib/storage";
import useCall from "src/lib/useCall";
import {formatTime, handleMicrophoneError} from "./common/utils";

// Reducer
import {setInit, setUnavailable, setUnknown} from "src/redux/crmCallingReducer";


const WIDTH = 600;
const HEIGHT = 300;

const Calling = (props) => {

    const dispatch = useDispatch();
    const { callState, phoneNumber, data, callType } = useSelector((state) => state.calling);
    const { dialCall, timeElapsed, enableMicro, onHandleMicro, terminateCall } = useCall(phoneNumber);

    const [isExtend, setIsExtend] = useState(true);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [size, setSize] = useState({ width: WIDTH, height: HEIGHT });
    const [loading, setLoading] = useState(true);
    const [zenifyError, setZenifyError] = useState(false);
    const [microphoneError, setMicrophoneError] = useState('');

    const prevCallStateRef = useRef([]);
    const tokenCall = localStorage.getItem(STORAGE.TOKEN_CALL);

    useEffect(() => {
        if (callState === CALL_STATE.RINGING) {
            prevCallStateRef.current.push(CALL_STATE.RINGING);
        } else if (callState === CALL_STATE.CONNECTED) {
            prevCallStateRef.current.push(CALL_STATE.CONNECTED);
            setIsExtend(false);
            setSize({ width: 300, height: 55 });
        } else if ([CALL_STATE.ENDED, CALL_STATE.NO_RESPONSE].includes(callState)) {
            setIsExtend(true);
            setSize({ width: WIDTH, height: HEIGHT });
        }
    }, [callState]);

    useEffect(() => {
        if (typeof window.ZenifyApp === 'undefined') {
            setZenifyError(true)
            setLoading(false)
            return
        }

        const requestMicrophoneAccess = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true })
                setMicrophoneError('')
            } catch (error) {
                setMicrophoneError(handleMicrophoneError(error));
            } finally {
                setTimeout(() => setLoading(false), 200)
            }
        };
        if(callType === CALL_TYPE.CALL_INBOUND) {
            setLoading(false)
            return
        }
        requestMicrophoneAccess().then()

        return () => {
            prevCallStateRef.current = []
        }
    }, [])

    useEffect(() => {
        const centerPosition = () => {
            const newX =
                isExtend ? (window.innerWidth - size.width) / 2
                    : window.innerWidth - size.width - 20
            const newY = isExtend ? (window.innerHeight - size.height) / 3 : 20
            setPosition({ x: newX, y: newY })
        };
        centerPosition()
        window.addEventListener('resize', centerPosition)
        return () => window.removeEventListener('resize', centerPosition)
    }, [isExtend, size])

    useEffect(() => {
        if (!loading && !zenifyError && !microphoneError && callState === CALL_STATE.INIT) {
            dialCall()
        }
    }, [loading, zenifyError, microphoneError, callState, dialCall, phoneNumber])

    const onCallLog = () => {
        CRMService.callLog({phone: phoneNumber, code_call: data.callId})
            .then(() => console.log('Call log success'))
            .catch((err) => console.error('Call log error:', err));
    }

    useEffect(() => {
        if ([CALL_STATE.ENDED, CALL_STATE.NO_RESPONSE].includes(callState) && callType === CALL_TYPE.CALL_INBOUND) {
            onCallLog();
        }
    }, [callState, onCallLog]);

    const toggleExtend = () => {
        setIsExtend((prev) => {
            const newExtended = !prev;
            setSize(newExtended ? { width: WIDTH, height: HEIGHT } : { width: 300, height: 55 });
            return newExtended;
        });
    };

    const handleTerminate = () => {
        if (!tokenCall) dispatch(setUnknown({ type: "invalid token" }))
        if ([CALL_STATE.CONNECTED, CALL_STATE.RINGING].includes(callState)) terminateCall()
    };

    // render
    const renderTimer = (isExtend = true) => {
        return (
            <div style={{ display: 'flex', flexDirection: isExtend ? 'column' : 'row', justifyContent: 'center' }}>
                <div style={{ fontWeight: 'bolder', padding: isExtend ? '5px 0' : 'unset' }}>
                    {isExtend ? 'Thời lượng cuộc gọi' : 'Thời lượng: '}
                </div>
                <div style={{ marginTop: isExtend ? '5px' : '0', marginLeft: isExtend ? '0' : '5px' }}>
                    {formatTime(timeElapsed)}
                </div>
            </div>
        );
    };

    const renderContentCall = () => {
        let comp
        switch (callState) {
            case CALL_STATE.INIT:
            case CALL_STATE.RINGING:
            case CALL_STATE.CONNECTED:
                comp = (
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                        <div style={{
                            margin: '40px 20px',
                            textAlign: 'center',
                            minHeight: '50px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {callState === CALL_STATE.INIT && <LoadingText text="Đang kết nối"/>}
                            {callState === CALL_STATE.RINGING && <LoadingText text="Đang đổ chuông"/>}
                            {callState === CALL_STATE.CONNECTED && renderTimer()}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', padding: '40px 0'}}>
                            <Tooltip title={enableMicro ? "Tắt micro" : "Bật micro"}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                        borderColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        pointerEvents: callState === CALL_STATE.CONNECTED ? 'auto' : 'none',
                                    }}
                                    onClick={onHandleMicro}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"} color={'white'}/>}
                                    size={'large'}
                                />
                            </Tooltip>
                            <Tooltip title={'Kết thúc cuộc gọi'}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: '#FF4D4F',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={handleTerminate}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                    size={'large'}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )
                break
            case CALL_STATE.ENDED:
            case CALL_STATE.NO_RESPONSE:
                comp = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px 0px'}}>
                        {callState === CALL_STATE.ENDED && (
                            <>
                                <div style={{fontWeight: 'bolder', fontSize: '18px'}}>Cuộc gọi đã kết thúc</div>
                                <div style={{
                                    fontWeight: 'bolder',
                                    padding: '15px 0'
                                }}>{`Thời lượng cuộc gọi: ${formatTime(timeElapsed)}`}</div>
                            </>
                        )}
                        {callState === CALL_STATE.NO_RESPONSE && (
                            <div style={{fontWeight: 'bolder', fontSize: '18px'}}>Người nhận không nhấc máy</div>
                        )}
                        <div style={{display: 'flex', paddingTop: '40px'}}>
                            <div style={{padding: '0 10px'}}>
                                <Button
                                    style={{
                                        margin: '0 8px',
                                        backgroundColor: '#D3D3D3',
                                        borderColor: '#D3D3D3',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => dispatch(setUnavailable())}
                                    type="primary"
                                    shape="circle"
                                    icon={<CloseOutlined color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Đóng</div>
                            </div>
                            <div style={{padding: '0 8px'}}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: '#1890ff',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => {
                                        dispatch(setInit({ phoneNumber, data: { callType: CALL_TYPE.CALL_OUTBOUND }, callType: CALL_TYPE.CALL_OUTBOUND }))
                                    }}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Gọi lại</div>
                            </div>
                        </div>
                    </div>
                )
                break
            case CALL_STATE.UNKNOWN:
                comp = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px 0px'}}>
                        <div style={{fontWeight: 'bolder', fontSize: '18px'}}>Đã kết thúc cuộc gọi</div>
                        <div style={{ fontSize: '18px'}} />
                        <div style={{ padding: '15px 0'}} />
                        <div style={{display: 'flex', paddingTop: '40px'}}>
                            <div style={{padding: '0 10px'}}>
                                <Button
                                    style={{
                                        margin: '0 8px',
                                        backgroundColor: '#D3D3D3',
                                        borderColor: '#D3D3D3',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => dispatch(setUnavailable())}
                                    type="primary"
                                    shape="circle"
                                    icon={<CloseOutlined color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Đóng</div>
                            </div>
                            <div style={{padding: '0 8px'}}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: '#1890ff',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => {
                                        dispatch(setInit({ phoneNumber, data: { callType: CALL_TYPE.CALL_OUTBOUND }, callType: CALL_TYPE.CALL_OUTBOUND }))
                                    }}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Gọi lại</div>
                            </div>
                        </div>
                    </div>
                )
                break
            default:
                comp = null
                break
        }
        return (
            <div style={{cursor: isExtend ? "unset" : "move", width: '100%', height: '100%'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isExtend ? 'unset' : 'center',
                    cursor: isExtend ? 'unset' : 'move',
                    height: '100%'
                }}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        {!isExtend && (
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                {renderTimer(false)}
                                <div style={{paddingRight: '8px'}}>
                                    <Tooltip title={enableMicro ? "Tắt micro" : "Bật micro"}>
                                        <Button
                                            style={{
                                                margin: '0 5px',
                                                backgroundColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                                borderColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            onClick={onHandleMicro}
                                            type="primary"
                                            shape="circle"
                                            icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"}
                                                            color={'white'}/>}
                                            size={'middle'}
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Kết thúc cuộc gọi'}>
                                        <Button
                                            style={{
                                                margin: '0 5px',
                                                backgroundColor: '#FF4D4F',
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            onClick={handleTerminate}
                                            type="primary"
                                            shape="circle"
                                            icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                            size={'middle'}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        <div onClick={toggleExtend} style={{cursor: 'pointer', padding: '2px 6px', pointerEvents: callState === CALL_STATE.CONNECTED ? 'auto' : 'none'}} >
                            {isExtend ? <ShrinkOutlined style={{fontSize: '20px', opacity: callState === CALL_STATE.CONNECTED ? 1 : 0.5 }} /> :
                                <ArrowsAltOutlined style={{fontSize: '20px'}}/>}
                        </div>
                    </div>
                    {isExtend && comp}
                </div>
            </div>
        )
    }

    const renderContent = () => {
        if (zenifyError) {
            return (
                <div style={{textAlign: 'center', fontWeight: 'bolder', margin: '50px 0', padding: '20px' }}>
                    Đã xảy ra lỗi: Zenify không được tải. Vui lòng liên hệ với quản trị viên.
                </div>
            );
        }
        return renderContentCall()
    };

    return (
        <Rnd
            position={position}
            size={size}
            enableResizing={false}
            onDragStop={(e, d) => setPosition({x: d.x, y: d.y})}
            onDragStart={(e) => isExtend && e.preventDefault()}
            style={{
                border: '1px solid #007bff',
                borderRadius: '10px',
                boxShadow: '0 8px 20px rgba(0, 123, 255, 0.5)',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                position: 'relative',
                pointerEvents: 'auto',
            }}
        >
            {loading ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <LoadingOutlined style={{fontSize: '40px', color: '#007bff'}} spin/>
                </div>
            ) : microphoneError ? (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <div
                            onClick={() => dispatch(setUnavailable())}
                            style={{cursor: 'pointer', padding: '2px 6px'}}
                        >
                            <CloseOutlined style={{fontSize: '20px'}}/>
                        </div>
                    </div>
                    <div style={{textAlign: 'center', fontWeight: 'bolder', margin: '50px 0', padding: '20px'}}>
                        {microphoneError}
                    </div>
                </div>
            ) : renderContent()}
        </Rnd>
    );
};

export default Calling;
