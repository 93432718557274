import store from "./redux/store";
import {setConnected, setEnded, setInitNotify, setNoResponse, setRinging} from "./redux/crmCallingReducer";
import {CALL_TYPE, ZENIFY_CONFIG} from "./constants/constants";

export const ZenifyApp = window.ZenifyApp || {}
export const ZenifyConfig = window.ZenifyConfig || ZENIFY_CONFIG

export function initZenifyApp() {
    const originalTitle = document.title;

    if (ZenifyApp && typeof ZenifyApp.subscribe === 'function') {
        ZenifyApp.subscribe((msg) => {
            console.log('Zenify event:', msg)
            const event_code = msg.objectId;
            const data = msg.data || {};
            switch (event_code) {
                case ZenifyConfig.NEW_NOTIFICATION_ID: // tiền xử lý cuộc gọi đến nếu cần
                    break
                case ZenifyConfig.RING_AGENT_RESPOND_ID:
                    if (data.callType === CALL_TYPE.CALL_INBOUND) {
                        // tách luồng khởi tạo thông báo cuộc gọi đến
                        store.dispatch(setInitNotify({ data, phoneNumber: data.caller, callType: CALL_TYPE.CALL_INBOUND }))
                        return
                    }
                    store.dispatch(setRinging({ data }))
                    break
                case ZenifyConfig.AGENT_ANSWER_RESPOND_ID:
                    store.dispatch(setConnected({ data }))
                    document.title = originalTitle;
                    break
                case ZenifyConfig.OUTCALL_ERROR_RESPONSE_ID:
                case ZenifyConfig.AGENT_END_CALL_RESPOND_ID:
                    store.dispatch(setEnded({ data }))
                    document.title = originalTitle;
                    break
                case ZenifyConfig.END_CALL_RESPOND_ID:
                    store.dispatch(setNoResponse({ data }))
                    document.title = originalTitle;
                    break
                default:
                    break
            }
        })
    } else {
        console.warn("ZenifyApp.subscribe is undefined. Subscription is skipped.");
    }
}
