import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {Button, notification} from 'antd'
import dayjs from 'dayjs'
import {useNavigate} from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import ROUTER from "src/router"
import STORAGE from "src/lib/storage"
import CRMService from "src/services/CRMService"

const NotificationScheduler = ({ triggers, notificationDuration, soundUrl }) => {
    const timerRef = useRef(null)
    const innerTimerRef = useRef(null)
    const audioRef = useRef(null)

    const role = localStorage.getItem(STORAGE.ROLES)

    const navigate = useNavigate()

    const checkNotificationAPI = async () => {
        try {
            const response = await CRMService.getOrdersProcessed()
            const { data } = response
            if (data) {
                const {
                    orderProcessing = 0,
                    orderPendingReDispatch = 0,
                    orderDispatched = 0,
                    orderReturned = 0,
                } = data
                const total = orderProcessing + orderPendingReDispatch + orderDispatched + orderReturned
                return total > 0
            }
            return false
        } catch (error) {
            console.error("API call error:", error)
            return false
        }
    }

    const defaultTriggers = [
        { hour: 11, minute: 0, message: 'Nhắc nhở định kỳ', description: 'Bạn có đơn hàng cần xử lý trong Dashboard > Đơn hàng.' },
        { hour: 15, minute: 0, message: 'Nhắc nhở định kỳ', description: 'Bạn có đơn hàng cần xử lý trong Dashboard > Đơn hàng.' },
        { hour: 17, minute: 0, message: 'Nhắc nhở định kỳ', description: 'Bạn có đơn hàng cần xử lý trong Dashboard > Đơn hàng.' },
    ]

    const scheduleTriggers = triggers && triggers.length ? triggers : defaultTriggers

    const scheduleNextNotification = () => {
        const now = dayjs()
        let nextTrigger = null

        const sortedTriggers = [...scheduleTriggers].sort((a, b) => {
            if (a.hour !== b.hour) return a.hour - b.hour
            return a.minute - b.minute
        })

        // Tìm trigger đầu tiên trong ngày mà thời gian hiện tại chưa đến
        for (let i = 0; i < sortedTriggers.length; i++) {
            const t = sortedTriggers[i]
            const targetTime = now
                .set('hour', t.hour)
                .set('minute', t.minute)
                .set('second', 0)
                .set('millisecond', 0)
            if (now.isBefore(targetTime)) {
                nextTrigger = { ...t, targetTime }
                break
            }
        }

        // TH không đóng ứng dụng
        // Nếu tất cả các trigger của hôm nay đã qua, đặt trigger kế tiếp vào mốc đầu tiên của ngày mai
        if (!nextTrigger) {
            const t = sortedTriggers[0]
            const targetTime = now
                .add(1, 'day')
                .set('hour', t.hour)
                .set('minute', t.minute)
                .set('second', 0)
                .set('millisecond', 0)
            nextTrigger = { ...t, targetTime }
        }

        const delay = nextTrigger.targetTime.diff(now)
        const apiThreshold = 3 * 60 * 1000 // 3 phút

        if (delay > apiThreshold) {
            // Call api trước 3 phút nếu chênh lệch giữa thời điểm hiện tại với thời điểm đẩy thông báo lớn hơn 3 phút
            timerRef.current = setTimeout(async () => {
                const shouldNotify = await checkNotificationAPI()
                if (shouldNotify) {
                    innerTimerRef.current = setTimeout(() => {
                        triggerNotification(nextTrigger)
                    }, apiThreshold)
                } else {
                    scheduleNextNotification()
                }
            }, delay - apiThreshold)
        } else {
            (async () => {
                const shouldNotify = await checkNotificationAPI()
                if (shouldNotify) {
                    timerRef.current = setTimeout(() => {
                        triggerNotification(nextTrigger)
                    }, delay)
                } else {
                    setTimeout(scheduleNextNotification, apiThreshold - delay)
                }
            })()
        }
    }

    const triggerNotification = (trigger) => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error("Không thể phát âm thanh:", error)
            })
        }
        notification.open({
            message: trigger.message,
            description: (
                <div style={{ animation: 'shake 0.5s infinite' }}>
                    {trigger.description}
                </div>
            ),
            icon: <ExclamationCircleOutlined className="incoming-call-icon" style={{ color: 'red', fontSize: '24px' }} />,
            duration: notificationDuration,
            btn: (
                <Button
                    style={{ marginRight: "10px" }}
                    type="primary"
                    onClick={() => {
                        if (audioRef.current) {
                            audioRef.current.pause()
                            audioRef.current.currentTime = 0
                        }
                        navigate(ROUTER.DASHBOARD)
                        notification.destroy()
                    }}
                >
                    Đi xử lý
                </Button>
            ),
            onClose: () => {
                if (audioRef.current) {
                    audioRef.current.pause()
                    audioRef.current.currentTime = 0
                }
            },
        })

        scheduleNextNotification()
    }

    useEffect(() => {
        if (role !== "ADMIN_ROLE") {
            scheduleNextNotification()
        }
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current)
            if (innerTimerRef.current) clearTimeout(innerTimerRef.current)
        }
    }, [triggers, notificationDuration, soundUrl])

    return <audio ref={audioRef} src={soundUrl} loop />
}

NotificationScheduler.propTypes = {
    triggers: PropTypes.arrayOf(
        PropTypes.shape({
            hour: PropTypes.number.isRequired,
            minute: PropTypes.number.isRequired,
            message: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    notificationDuration: PropTypes.number,
    soundUrl: PropTypes.string,
}

NotificationScheduler.defaultProps = {
    triggers: null,
    notificationDuration: 20,
    soundUrl: '/sounds/incommingCall.mp3',
}

export default NotificationScheduler
