export const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
};

export const getBrowser = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) return "Chrome";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Edg")) return "Edge";
    return "Unknown";
};

export const renderBrowserInstructions = () => {
    const instructions = {
        Chrome: "Bạn chưa cấp quyền truy cập microphone trên Google Chrome. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Cài đặt trang, sau đó chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Firefox: "Bạn chưa cấp quyền truy cập microphone trên Mozilla Firefox. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Safari: "Bạn chưa cấp quyền truy cập microphone trên Safari. Vui lòng mở Safari, chọn Tùy chọn, vào tab Trang web, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Edge: "Bạn chưa cấp quyền truy cập microphone trên Microsoft Edge. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Cài đặt quyền trang web, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang."
    };
    return instructions[getBrowser()] || "Bạn chưa cấp quyền truy cập microphone. Vui lòng kiểm tra cài đặt trình duyệt và cấp quyền cho microphone.";
};

export const handleMicrophoneError = (error) => {
    if (error.name === 'NotAllowedError') return renderBrowserInstructions();
    if (error.name === 'NotFoundError') return "Không tìm thấy thiết bị microphone. Vui lòng kiểm tra lại kết nối hoặc đảm bảo rằng thiết bị của bạn có microphone hoạt động.";
    return error.message;
};