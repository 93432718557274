import { createSlice } from '@reduxjs/toolkit';
import {CALL_STATE} from "src/constants/constants";

const initialState = {
    callState: 'UNAVAILABLE', // 'INIT' | 'RINGING' | 'CONNECTED' | 'ENDED' | 'NO_RESPONSE | UNKNOWN
    phoneNumber: '',
    data: {},
    callType: ''
};

const callSlice = createSlice({
    name: 'calling',
    initialState,
    reducers: {
        setUnavailable: () => {
            return initialState
        },
        // incoming call
        setInitNotify: (state, action) => {
            state.callState = CALL_STATE.NEW_NOTIFY;
            state.phoneNumber = action.payload.phoneNumber
            state.callType = action.payload.callType
            state.data = action.payload.data
        },
        setInit: (state, action) => {
            state.callState = CALL_STATE.INIT;
            state.phoneNumber = action.payload.phoneNumber
            state.callType = action.payload.callType
            state.data = action.payload.data
        },
        setRinging: (state, action) => {
            state.callState = CALL_STATE.RINGING;
        },
        setConnected: (state, action) => {
            state.callState = CALL_STATE.CONNECTED;
            state.data = action.payload.data
        },
        setEnded: (state, action) => {
            state.callState = CALL_STATE.ENDED;
            state.data = action.payload.data
        },
        setNoResponse: (state, action) => {
            state.callState = CALL_STATE.NO_RESPONSE;
            state.data = action.payload.data
        },
        setUnknown: (state, action) => {
            state.callState = CALL_STATE.UNKNOWN;
            state.type = action.payload.type; // Chủ động truyền type trong trường hợp không xác định được event call
        },
    },
});

export const {
    setInitNotify,
    setUnavailable,
    setInit,
    setRinging,
    setConnected,
    setEnded,
    setNoResponse,
    setUnknown
} = callSlice.actions;
export default callSlice.reducer;
