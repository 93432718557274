import QueryString from "qs"
import http from "../index"
import {
  apiCountry,
  apiDeletePersonal,
  apiGetListBonus,
  apiPersonnelCreate,
  apiPersonnelDetail,
  apiPersonnelUpdate,
  apiProvince,
  apiWorkHistoryCreate,
  apiWorkHistoryList,
  apiWorkingProcessList,
  apiAllowanceHistory,
  apiListSalaryHistory,
  apiWorkingProcessCreate,
  apiWorkingProcessDelete,
  apiWorkingProcessUpdate,
  apiSalaryHistoryCreate,
  apiSalaryHistoryUpdate,
  apiSalaryHistoryDelete,
  apiGetListContract,
  apiContractCreate,
  apiContractUpdate,
  apiListInsurance,
  apiGetDepartmentList,
  apiPersonnelListByDeptId,
  apiGetContractTypeList,
  apiGetSignatoryList,
  apiGetContractDetail,
  apiTimekeepingList,
  apiTimekeepingImportFile,
  apiInsuranceDetail,
  apiInsuranceCreate,
  apiInsurancePayment,
  apiApprove,
  apilistWorkDay,
  apiListContact,
  apiImport,
  apiContactCallToken,
  apiListOrder,
  apiListOrderDelete,
  apiGetCustomerListAll,
  apiOrderCreate,
  apiGetContactDetail,
  apiUpdateContactStatus,
  apiGetCustomerList,
  apiGetAgentListAll,
  apiAddAgentPersonnel,
  apiGetPositionList,
  apiGetCustomerDetail,
  apiUpdateCustomer,
  apiRemoveAgentPersonnel,
  apiGetOrderDetail,
  apiCustomerImport,
  apiOrderUpdate,
  apiUnlockUser,
  apiLockUser, apiDeleteContact, apiCreateOrderSpecial, apiResetPassword,
} from "./urls"

const personnelDetail = id => http.get(apiPersonnelDetail(id))
const deletePersonal = params => http.delete(apiDeletePersonal, { params })
const getListBonus = params => http.get(apiGetListBonus, { params })
const workHistoryList = body =>
  http.get(apiWorkHistoryList + `?${QueryString.stringify(body)}`)
const workHistoryCreate = body => http.post(apiWorkHistoryCreate({ body }))
const personnelUpdate = (id, body) => http.put(apiPersonnelUpdate(id), body)
const personnelCreate = body => http.post(apiPersonnelCreate, body)

const getProvince = body =>
  http.get(apiProvince + `?${QueryString.stringify(body)}`)
const getCountry = () => http.get(apiCountry)
const getWorkingProcessList = body =>
  http.get(apiWorkingProcessList + `?${QueryString.stringify(body)}`)
const workingProcessCreate = body => http.post(apiWorkingProcessCreate, body)
const workingProcessDelete = params =>
  http.delete(apiWorkingProcessDelete, { params })
const workingProcessUpdate = (id, body) =>
  http.put(apiWorkingProcessUpdate(id), body)
const getListSalaryHistory = body =>
  http.get(apiListSalaryHistory + `?${QueryString.stringify(body)}`)
const getAllowanceHistory = body =>
  http.get(apiAllowanceHistory + `?${QueryString.stringify(body)}`)
const salaryHistoryCreate = body => http.post(apiSalaryHistoryCreate, body)
const salaryHistoryUpdate = (id, body) =>
  http.put(apiSalaryHistoryUpdate(id), body)
const salaryHistoryDelete = params =>
  http.delete(apiSalaryHistoryDelete, { params })
const getContractList = params => http.get(apiGetListContract, { params })
const contractCreate = body => http.post(apiContractCreate, body)
const contractUpdate = (id, body) => http.put(apiContractUpdate(id), body)
const getInsuranceList = params => http.get(apiListInsurance, { params })
const getInsuranceDetail = id => http.get(apiInsuranceDetail(id))
const insuranceCreate = body => http.post(apiInsuranceCreate, body)
const getInsurancePayment = id => http.get(apiInsurancePayment(id))
const getDepartmentList = () => http.get(apiGetDepartmentList)
const getPositionList = () => http.get(apiGetPositionList)
const getPersonnelListByDeptId = params =>
  http.get(apiPersonnelListByDeptId, { params })
const getContractTypeList = () => http.get(apiGetContractTypeList)
const getSignatoryList = () => http.get(apiGetSignatoryList)
const getContractDetail = id => http.get(apiGetContractDetail(id))
const getTimekeepingList = params => http.get(apiTimekeepingList, { params })
const apiListWorkDay = params => http.get(apilistWorkDay, { params })
const getApiApprove = body => http.post(apiApprove, body )
const getTimekeepingImportFile = params =>
  http.post(apiTimekeepingImportFile, params)
const getContactList = params => http.get(apiListContact, { params })
const deleteContactList = params => http.delete(apiDeleteContact, { params })
const getContactDetail = id => http.get(apiGetContactDetail(id))
const updateContactStatus = (id, body) => http.put(apiUpdateContactStatus(id), body)
const getCustomerListAll = () => http.get(apiGetCustomerListAll)
const getCustomerList = params => http.get(apiGetCustomerList, { params })
const getCustomerDetail = id => http.get(apiGetCustomerDetail(id))
const updateCustomer = (id, body) => http.put(apiUpdateCustomer(id), body)
const getImport = params => http.post(apiImport,  params )
const customerImport = params => http.post(apiCustomerImport,  params )
const getContactCallToken = params => http.post(apiContactCallToken, params)
const getOrderList = params => http.get(apiListOrder, { params })
const deleteOrder = params => http.delete(apiListOrderDelete, { params })
const orderCreate = body => http.post(apiOrderCreate, body)
const orderCreateSpecial = body => http.post(apiCreateOrderSpecial, body)
const getOrderDetail = id => http.get(apiGetOrderDetail(id))
const orderUpdate = (id, body) => http.put(apiOrderUpdate(id), body)
const getAgentListAll = () => http.get(apiGetAgentListAll)
const addAgentPersonnel = body => http.put(apiAddAgentPersonnel, body)
const removeAgentPersonnel = body => http.put(apiRemoveAgentPersonnel, body)
const unlockUser = (id, body) => http.post(apiUnlockUser(id), body)
const lockUser = (id, body) => http.post(apiLockUser(id), body)
const resetPassword = (id, body) => http.put(apiResetPassword(id), body)

const HRService = {
  customerImport,
  getImport,
  apiListWorkDay,
  getApiApprove,
  getTimekeepingImportFile,
  getTimekeepingList,
  personnelDetail,
  deletePersonal,
  getListBonus,
  workHistoryList,
  workHistoryCreate,
  personnelUpdate,
  personnelCreate,
  getProvince,
  getCountry,
  getWorkingProcessList,
  getAllowanceHistory,
  getListSalaryHistory,
  salaryHistoryCreate,
  salaryHistoryUpdate,
  salaryHistoryDelete,
  workingProcessCreate,
  workingProcessDelete,
  workingProcessUpdate,
  getContractList,
  contractCreate,
  contractUpdate,
  getInsuranceList,
  getInsuranceDetail,
  insuranceCreate,
  getDepartmentList,
  getPositionList,
  getPersonnelListByDeptId,
  getContractTypeList,
  getSignatoryList,
  getContractDetail,
  getInsurancePayment,
  getContactList,
  deleteContactList,
  getOrderList,
  getContactCallToken,
  getCustomerListAll,
  getCustomerList,
  orderCreate,
  orderCreateSpecial,
  getContactDetail,
  updateContactStatus,
  getAgentListAll,
  addAgentPersonnel,
  getCustomerDetail,
  updateCustomer,
  removeAgentPersonnel,
  getOrderDetail,
  orderUpdate,
  unlockUser,
  lockUser,
  deleteOrder,
  resetPassword
}

export default HRService

