import { flatMap, forEach, isArray, isEmpty } from "lodash"
import moment from "moment"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import { DATE_FORMAT, formatDate } from "./dateFormatters"
import { fdtfM2 } from "./fdtf"
import { Anchor } from "antd"
const { Link } = Anchor

export const isMobileBrowser = () => {
  if (typeof window === 'undefined') return false;
  return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          navigator.userAgent.substr(0, 4)
      )
  );
};

export const isMobileConst = isMobileBrowser();

export const formatTime = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  const hh = h.toString().padStart(2, "0");
  const mm = m.toString().padStart(2, "0");
  const ss = s.toString().padStart(2, "0");

  return h > 0 ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
}

export const maskPhoneNumber = (phone = "") => {
  if (!phone) return phone;
  return `${phone.slice(0, 2)}*******${phone.slice(-2)}`;
};

export const removeKeyDown = () => {
  document.onkeydown = null
}

export const formatCurrency = (value) => {
  const rawValue = value.replace(/[^0-9]/g, '');
  return rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getListComboByKey = (key, listSystemKey) => {
  const parent = listSystemKey?.find(x => x.CodeKey === key)
  if (parent)
    return listSystemKey
      ?.filter(x => x.ParentID === parent.ID)
      ?.sort((a, b) => {
        return a.SortOrder - b.SortOrder
      })
  return []
}

export const updateTreeData = (list, key, children) => {
  let arr = children.map(child => {
    return {
      ...child,
      title: child.regionName,
      key: child.regionID,
      isLeaf: child.regionLevel === 4,
    }
  })
  return list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children: arr,
      }
    }

    if (node?.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      }
    }
    return node
  })
}

// export const hasPermission = (TabID, listTab) => {
//   if (!TabID || TabID.length === 0) return true
//   const IsVisitTab = listTab.some(item =>
//     TabID.some(tab => tab === item.CategoryID && item.IsVistTab === true),
//   )
//   return IsVisitTab
// }
export const hasPermission = (TabID, listTabs) => {
  if (!TabID || TabID.length === 0) return true;

  const hasPermission = listTabs.some(item =>
    TabID.includes(item.CategoryID) && item.IsVistTab === true
  );

  return hasPermission;
};

export const renderStringTestForm = arr => {
  let array = []
  arr.forEach(item => {
    if (item === 1) {
      array.push(" Tại nhà")
    }
    if (item === 2) {
      array.push(" Bệnh viện/Phòng khám ")
    }
  })
  return array.toString()
}

export const loop = (a, b) => {
  let result = []
  a.forEach(a1 => {
    if (b.some(item => item.accountId === a1.accountId)) return
    result.push(a1)
  })
  return result
}

export const groupBy = (input, string) => {
  let result = []
  input.forEach(ele => {
    if (ele.level === 1) return
    if (result.find(item => item.serviceId === ele[string])) {
      const exitsItem = result.find(item => item.serviceId === ele[string])
      exitsItem.ltExamination.push(ele.key)
    } else {
      result.push({
        serviceId: ele[string],
        ltExamination: [ele.key],
      })
    }
  })
  return result
}

export const convertTreeData = (
  listData,
  withAnchor = false,
  id,
  name,
  parent,
  levelMin = 1,
) => {
  if (!listData || !listData.length) return []
  const listRoot = listData.filter(x => x.Level === levelMin)
  const listOther = listData.filter(y => y.Level !== levelMin)
  const treeDataConvert = convertChildrent(
    listRoot,
    listOther,
    withAnchor,
    id,
    name,
    parent,
  )
  return treeDataConvert
}

const convertChildrent = (
  listRoot,
  listOther,
  withAnchor,
  id,
  name,
  parent,
) => {
  const newList = listRoot.map(root => {
    const newItem = {
      ...root,
      title: withAnchor ? (
        <Link href={`#${root[id]}`} title={root[name]} />
      ) : (
        root[name]
      ),
      label: root[name],
      key: root[id],
      id: root[id],
      Status: root["Status"],
    }
    const listChild = listOther.filter(x => x[parent] === root[id])
    const listOtherChild = listOther.filter(y => y[parent] !== root[id])
    if (listChild && listChild.length)
      return {
        ...newItem,
        children: convertChildrent(
          listChild,
          listOtherChild,
          withAnchor,
          id,
          name,
          parent,
        ),
      }
    return newItem
  })
  return newList
}

export const convertTreeRegion = listData => {
  if (!listData || !listData.length) return []
  const listRoot = listData.filter(x => x.regionLevel === 2)
  const listOther = listData.filter(y => y.regionLevel !== 2)
  const treeDataConvert = convertChildrentRegion(listRoot, listOther)

  return treeDataConvert
}

const convertChildrentRegion = (listRoot, listOther) => {
  const newList = listRoot.map(root => {
    const newItem = {
      ...root,
      title: root.regionName,
      key: root.regionID,
      Status: root.Status,
      isLeaf: root.regionLevel === 4,
    }
    const listChild = listOther.filter(x => x.parentID === root.regionID)
    const listOtherChild = listOther.filter(y => y.parentID !== root.regionID)
    if (listChild && listChild.length)
      return {
        ...newItem,
        children: convertChildrentRegion(listChild, listOtherChild),
      }
    return newItem
  })
  return newList
}

export const convertData = listRoot => {
  const newList = listRoot.map(root => {
    const newItem = {
      ...root,
      level: root.level,
      title: root.serviceName,
      price: root.price,
      note: root.note,
      serviceType: root.serviceType,
      key: root.serviceId,
      serviceId: root.serviceId,
      isLeaf: root.listService && root.listService.length > 0 ? false : true,
    }
    if (root.listService && root.listService.length)
      return {
        ...newItem,
        children: convertData(root.listService),
      }
    return newItem
  })
  return newList
}
export const convertTreeDataUser = listData => {
  if (!listData || !listData.length) return []
  const listRoot = listData.filter(i => i.DeptLevel === 1)
  const listOther = listData.filter(i => i.DeptLevel !== 1)
  const treeDataConvert = convertChildrentUser(listRoot, listOther)
  return treeDataConvert
}
const convertChildrentUser = (listRoot, listOther) => {
  const newList = listRoot.map((root, idx) => {
    const newItem = {
      ...root,
      name: root.DeptName,
      label: root.DeptName,
      title: root.DeptName,
      key: root.UserID || root.DeptID,
      value: root.UserID || root.DeptID,
      id: root.UserID || root.DeptID,
      selectable: !!root.UserID,
      disabled: !root.UserID,
    }
    const listChild = listOther.filter(i => i.DeptIDParent === root.DeptID)
    const listOtherChild = listOther.filter(i => i.DeptIDParent !== root.DeptID)
    if (listChild && listChild.length)
      return {
        ...newItem,
        children: convertChildrentUser(listChild, listOtherChild),
      }
    return newItem
  })
  return newList
}
export function submitFormWithCtrlS(formRef) {
  document.onkeydown = e => {
    if (e.ctrlKey && e.keyCode === 83) {
      e.preventDefault()
      formRef.current.submit()
    }
  }
}

export function submitFormWithCtrlKey(formRef, key = "s") {
  removeKeyDown()
  document.onkeydown = e => {
    if (e.ctrlKey && e.key === key.toLowerCase()) {
      e.preventDefault()
      formRef.current.submit()
    }
  }
}

export function findParent({ children = [], ...object }, key) {
  var result
  if (object.key === key) return object
  return (
    children.some(o => (result = findParent(o, key))) &&
    Object.assign({}, object, { children: [result] })
  )
}

export function convertTreeToList(root) {
  var stack = [],
    array = [],
    hashMap = {}
  stack.push(root)

  while (stack.length !== 0) {
    var node = stack.pop()
    if (!node.children) {
      visitNode(node, hashMap, array)
    } else {
      for (var i = node?.children?.length - 1; i >= 0; i--) {
        stack.push(node.children[i])
      }
    }
  }

  return array
}

function visitNode(node, hashMap, array) {
  if (!hashMap[node.key]) {
    hashMap[node.key] = true
    array.push(node)
  }
}

export function submitFormAntWithCtrlKey(form, key = 83) {
  document.onkeydown = e => {
    if (e.ctrlKey && e.keyCode === key) {
      e.preventDefault()
      form.submit()
    }
  }
}

export function handleCtrlKey(onHandle, key = "a") {
  const myFunc = e => {
    if (e.ctrlKey && e.key === key.toLowerCase()) {
      e.preventDefault()
      onHandle()
    }
  }
  document.addEventListener("keydown", myFunc)
  return myFunc
}

export function handlePressKey(onHandle, key = "a") {
  const myFunc = e => {
    if (e.key.toLowerCase() === key.toLowerCase()) {
      e.preventDefault()
      onHandle()
    }
  }
  document.addEventListener("keydown", myFunc)
  return myFunc
}

export function handleCtrlD(onSubmit) {
  document.onkeydown = e => {
    if (e.ctrlKey && e.keyCode === 68) {
      e.preventDefault()
      onSubmit()
    }
  }
}

export const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const trimData = data => {
  if (!data) return data
  const tempData = isArray(data) ? [] : {}
  forEach(data, (val, keyName) => {
    if (typeof val === "string") tempData[keyName] = val.trim()
    else if (typeof val === "object") tempData[keyName] = trimData(val)
    else tempData[keyName] = val
  })
  return tempData
}

export function submitFormUsingCtrlS(
  form,
  handleSubmit,
  isForm,
  formRef = null,
) {
  document.onkeydown = e => {
    if (e.ctrlKey && e.keyCode === 83) {
      e.preventDefault()
      if (formRef && formRef.current) {
        formRef.current.submit()
      } else if (isForm) {
        form.validateFields().then(values => {
          handleSubmit(values)
        })
      } else handleSubmit()
    }
  }
}

export const treeToListWithKey = (data = [], key = "value") => {
  let temVal = data
  forEach(data, item => {
    if (item.children) temVal = [...temVal, ...treeToList(item.children)]
  })
  return flatMap(temVal, item => item[key] || item)
}
export const treeToList = (data = []) => {
  let temVal = []
  forEach(data, item => {
    if (item.children) temVal = [...temVal, ...treeToList(item.children)]
    temVal = [...temVal, { ...item, children: undefined }]
  })
  return temVal?.filter(i => !isEmpty(i))
}
export const checkSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const checkTotalTime = (totalTime, workingDay) => {
  const dayOfWeek = moment(workingDay).day()
  const textTooltip = {
    enoughTime: "Ngày làm việc đủ thời lượng",
    lackOfTime: "Thiếu thời lượng của Ngày làm việc này",
    excessiveTime: "Thừa thời lượng của Ngày làm việc này",
  }
  const color = {
    enoughTime: "#212121",
    lackOfTime: "#BC2618",
    excessiveTime: "#18BC78",
  }
  const fontWeight = {
    enoughTime: "normal",
    lackOfTime: "bold",
    excessiveTime: "bold",
  }
  if (dayOfWeek === 0) return { text: "Ngày Chủ nhật", color: color.enoughTime }
  if (!totalTime) return
  if (dayOfWeek === 6) {
    if (totalTime >= 3.5 && totalTime <= 4) {
      return {
        text: textTooltip.enoughTime,
        color: color.enoughTime,
        fontWeight: fontWeight.enoughTime,
      }
    }
    if (totalTime < 3.5) {
      return {
        text: textTooltip.lackOfTime,
        color: color.lackOfTime,
        fontWeight: fontWeight.lackOfTime,
      }
    }
    if (totalTime > 4) {
      return {
        text: textTooltip.excessiveTime,
        color: color.excessiveTime,
        fontWeight: fontWeight.excessiveTime,
      }
    }
  } else {
    if (totalTime >= 7 && totalTime <= 8) {
      return {
        text: textTooltip.enoughTime,
        color: color.enoughTime,
        fontWeight: fontWeight.enoughTime,
      }
    }
    if (totalTime < 7) {
      return {
        text: textTooltip.lackOfTime,
        color: color.lackOfTime,
        fontWeight: fontWeight.lackOfTime,
      }
    }
    if (totalTime > 8) {
      return {
        text: textTooltip.excessiveTime,
        color: color.excessiveTime,
        fontWeight: fontWeight.excessiveTime,
      }
    }
  }
}

export const checkSumFunc = checkSum => {
  const statusSum = checkSum?.StatusSum
  const woringDayExcessive = checkSum?.WorkingDay_Thua?.map(item =>
    fdtfM2(item, "m2e"),
  )
  const workingDayLack = checkSum?.WorkingDay_Thieu?.map(item =>
    fdtfM2(item, "m2e"),
  )
  const status = {
    enough: 0,
    lack: 1,
    excessive: 2,
    lackExcessive: 3,
  }
  const color = {
    enough: "#212121",
    lack: "#BC2618",
    excessive: "#18BC78",
    lackExcessive: "#f2994b",
  }
  let result = {}
  switch (statusSum) {
    case status.enough:
      result = {
        color: color.enough,
        tooltip: "Đủ thời lượng",
        iconName: null,
        showIcon: false,
      }
      break
    case status.lack:
      result = {
        color: color.lack,
        tooltip: (
          <>
            - Có {workingDayLack?.length} Ngày làm việc thiếu thời lượng: <br />
            {workingDayLack?.join(", ")}{" "}
          </>
        ),
        iconName: "warning_red",
        showIcon: true,
      }
      break
    case status.excessive:
      result = {
        color: color.excessive,
        tooltip: (
          <>
            - Có {woringDayExcessive?.length} Ngày làm việc thừa thời lượng:{" "}
            <br />
            {woringDayExcessive?.join(", ")}
          </>
        ),
        iconName: "warning_green",
        showIcon: true,
      }
      break
    case status.lackExcessive:
      result = {
        color: color.lackExcessive,
        tooltip: (
          <>
            - Có {workingDayLack?.length} Ngày làm việc thiếu thời lượng: <br />
            {workingDayLack.join(", ")} <br />- Có {woringDayExcessive?.length}{" "}
            Ngày làm việc thừa thời lượng: <br />
            {woringDayExcessive.join(", ")}
          </>
        ),
        iconName: "warning_yellow",
        showIcon: true,
      }
      break
    default:
      break
  }
  return result
}

export const handleWorkingDay = (date, durationDate) => {
  let startDate = null
  let endDate = null
  const thisWeek = moment().startOf("week").toDate()
  const thisMonth = moment().startOf("months").toDate()
  const thisYear = moment().startOf("year").toDate()

  switch (date) {
    case 1:
      startDate = formatDate(thisWeek, DATE_FORMAT)
      endDate = formatDate(moment(), DATE_FORMAT)
      break
    case 2:
      startDate = formatDate(
        moment(thisWeek).subtract(1, "week").toDate(),
        DATE_FORMAT,
      )
      endDate = formatDate(moment(), DATE_FORMAT)
      break
    case 3:
      startDate = formatDate(thisMonth, DATE_FORMAT)
      endDate = formatDate(moment(), DATE_FORMAT)
      break
    case 4:
      startDate = formatDate(
        moment(thisMonth).subtract(1, "months").toDate(),
        DATE_FORMAT,
      )
      endDate = formatDate(moment(), DATE_FORMAT)
      break
    case 6:
      startDate = formatDate(moment(thisYear).toDate(), DATE_FORMAT)
      endDate = formatDate(moment(), DATE_FORMAT)
      break
    case 5:
      if (durationDate) {
        startDate = formatDate(durationDate[0], DATE_FORMAT)
        endDate = formatDate(durationDate[1], DATE_FORMAT)
      }
      break
    default:
      startDate = ""
      endDate = ""
  }

  return { startDate, endDate }
}

export const renderButtonCircle = (
  title,
  iconName,
  onClick,
  buttonCircle,
  btnType,
  fill,
  style,
) =>
  buttonCircle != null && (
    <ButtonCircle
      placement="bottomRight"
      title={title}
      iconName={iconName}
      onClick={onClick}
      enable={buttonCircle}
      btnType={btnType}
      fill={fill}
      style={style}
    />
  )

export const extracTreeData = (data, code, name) => {
  if (!data) return []
  return [
    {
      title: "Tất cả",
      value: "0",
      key: "0",
      children: data.map(item => {
        let title = `${item[code]} - ${item[name]}`
        if (!code) title = item[name]
        if (!name) title = item[code]
        return {
          title,
          value: item.id,
          children: [],
        }
      }),
    },
  ]
}

// contact list
export const nest = (items, id, link) =>
  items
    ?.filter(item => item[link] === id)
    .map(item => ({
      ...item,
      title: item.DepartmentName,
      label: item.DepartmentName,
      name: item.DepartmentName,
      value: item.DepartmentID,
      key: item.DepartmentID,
      children: nest(items, item?.DepartmentID, link),
    }))

export const treeValue = (items, id, link) =>
  items
    .filter(item => item[link] === id)
    .map(item => ({
      ...item,
      title: item.departmentName,
      value: item.departmentId,
      key: item.departmentId,
      children: treeValue(items, item?.departmentId, link),
    }))

export const checkPermission = (user, menu, action) => {
  const { listTab } = user
  const { nameFromApi } = menu
  return listTab
    ?.filter(i => i?.description === nameFromApi)
    ?.filter(j => j?.button?.includes(action))?.length
}

export const formatMoney = money =>
  (Math.round(money * 100) / 100).toLocaleString()
export const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const getRegexPassword = () => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  return regex
}

export const normFile = e => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}
export const arrayToTree = (items, id = null, parent = "parent_id", child) =>
  items
    .filter(item => item[parent] === id)
    .map(item => ({
      ...item,
      title: item?.DisplayName,
      value: item?.KeyID,
      children: arrayToTree(items, item[child], parent, child),
    }))

export const getRowSpans = (arr = [], key) => {
  let sameValueLength = 0
  const rowSpans = []
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i === 0) {
      rowSpans[i] = sameValueLength + 1
      continue
    }
    if (arr[i][key] === arr[i - 1][key]) {
      rowSpans[i] = 0
      sameValueLength++
    } else {
      rowSpans[i] = sameValueLength + 1
      sameValueLength = 0
    }
  }
  return rowSpans
}

export const listDataFile = data =>
  data?.map(item => ({
    name: item?.FileName,
    url: item?.FileUrl,
    uid: item?.ObjectFileID,
    ...item,
  }))
export const listUidFile = data => {
  if (!!Array.isArray(data) && !!data?.length)
    return data?.map(item => {
      if (!!item?.ObjectFileID) return item?.ObjectFileID
      else return ""
    })
  else {
    return [""]
  }
}
export const validatorFileExtension = (fileName, acceptFile) => {
  const parts = fileName.split(".")
  let type = ""
  if (parts.length > 1) {
    type = parts.pop()
  }
  if (!!type && acceptFile.includes(type)) return true
  return false
}

export const formatCurrencyVND = (number, withCurrencySymbol = false) => {
  if (withCurrencySymbol) {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
  } else {
    return new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 0 }).format(number);
  }
}
