import React, {useEffect} from 'react';
import DrawerCustom from "src/components/DrawerCustom";
import {Button, DatePicker, Form, Select, Space, Tag} from "antd";
import {SelectStyle} from "../../common/styled";
import {useDispatch, useSelector} from "react-redux";
import CRMService from "src/services/CRMService";
import {setListPersonnel, setListProvince} from "src/redux/hr";
import {statusCall} from "../../components/ContactCustomer";
import dayjs from "dayjs";
import {CloseOutlined} from "@ant-design/icons";
import CustomRangePicker from "src/components/CustomRangePickerMobile/CustomRangePicker";

function Index(props) {
    const {onCancel, openFilter, setFilter, filter } = props

    const dispatch = useDispatch();
    const { listProvince, listPersonnel } = useSelector((state) => state.hr)

    const [form] = Form.useForm()
    const selectedStatus = Form.useWatch('status', form)
    const selectedAssigner = Form.useWatch('personnel_id', form)

    const onSubmit = () => {
        form.validateFields().then(values => {
            const filter = Object.entries(values).reduce((acc, [key, value]) => {
                if (key === "date" && !!values.date) {
                    acc.start_date = dayjs(value[0]).format("YYYY-MM-DD");
                    acc.end_date = dayjs(value[1]).format("YYYY-MM-DD");
                } else if (key === "call_date" && !!values.call_date) {
                    acc.start_updated = dayjs(value[0]).format("YYYY-MM-DD");
                    acc.end_updated = dayjs(value[1]).format("YYYY-MM-DD");
                } else if (value && !(Array.isArray(value) && value.length === 0)) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            setFilter(filter);
            onCancel();
        });
    };


    useEffect(() => {
        form.setFieldsValue({
            personnel_id: filter.personnel_id,
            province: filter.province,
            status: filter.status,
        })
        if (filter.start_date) form.setFieldsValue({
            date: [dayjs(filter.start_date), dayjs(filter.end_date)]
        })
        if (filter.start_updated) form.setFieldsValue({
            call_date: [dayjs(filter.start_updated), dayjs(filter.end_updated)]
        })
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch Province
                if (listProvince.length === 0) {
                    const provinceRes = await CRMService.getProvince();
                    dispatch(setListProvince(provinceRes?.success || []));
                }

                // Fetch Personnel
                if (listPersonnel.length === 0) {
                    const personnelRes = await CRMService.getListPersonnel();
                    dispatch(setListPersonnel(personnelRes.data || []));
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchData().then()
    }, [])
    return (
        <DrawerCustom
            title={<span style={{ color: "#01638d" }}>Bộ lọc</span>}
            headerStyle={{ padding: "14px 7px" }}
            open={openFilter}
            closeIcon={<CloseOutlined style={{ color: "#01638d" }} />}
            onClose={onCancel}
            size="large"
            extra={
                <Space>
                    <Button onClick={() => form.resetFields()}>Thiết lập lại</Button>
                    <Button type="primary" onClick={onSubmit}>Áp dụng</Button>
                </Space>
            }
        >
            <Form
                form={form}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                labelAlign="left"
                initialValues={{ duration_prioritize: 12 }}
            >
                <Form.Item name="province" label="Chọn tỉnh thành">
                    <SelectStyle
                        mode="multiple"
                        showSearch
                        placeholder="Tỉnh thành"
                        optionLabelProp="label"
                        allowClear
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {listProvince.map(option => (
                            <Select.Option key={option.title} value={option.plate_code} label={option.title}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span>{option.title}</span>
                                    <Tag bordered={false} color="blue">
                                        {option?.plate_code}
                                    </Tag>
                                </div>
                            </Select.Option>
                        ))}
                    </SelectStyle>
                </Form.Item>
                <Form.Item name="personnel_id" label="Assigner">
                    <SelectStyle
                        placeholder="Assigner"
                        allowClear
                        style={{ height: '37px' }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            if (value === '') {
                                form.setFieldsValue({ personnel_id: undefined })
                            }
                        }}
                    >
                        {selectedAssigner && (
                            <Select.Option value="" style={{color: '#aaa'}}>
                                -- Bỏ chọn --
                            </Select.Option>
                        )}
                        {listPersonnel.map(item => (
                            <Select.Option key={item.ID} value={item.ID} label={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </SelectStyle>
                </Form.Item>
                <Form.Item name="status" label="Chọn trạng thái">
                    <SelectStyle
                        showSearch
                        placeholder="Trạng thái"
                        optionLabelProp="label"
                        style={{ height: '37px' }}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                            if (value === '') {
                                form.setFieldsValue({ status: undefined })
                            }
                        }}
                    >
                        {selectedStatus && (
                            <Select.Option value="" style={{color: '#aaa'}}>
                                -- Bỏ chọn --
                            </Select.Option>
                        )}
                        {statusCall.map(item => (
                            <Select.Option key={item.id} value={item.id} label={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </SelectStyle>
                </Form.Item>
                <Form.Item name="date" label="Thời gian bảo hiểm">
                    <CustomRangePicker
                        showTime
                        inputReadOnly
                        format="DD/MM/YYYY"
                        placeholder={['Bắt đầu BH', 'Kết thúc BH']}
                    />
                </Form.Item>
                <Form.Item name="call_date" label="Thời gian gọi">
                    <CustomRangePicker
                        showTime
                        inputReadOnly
                        format="DD/MM/YYYY"
                        placeholder={['Ngày gọi', 'Ngày gọi']}
                    />
                </Form.Item>
            </Form>
        </DrawerCustom>
    );
}

export default Index;